<template>
	<div class="section-8 sect-mobile">
		<div class="container-section-mobile" v-if="$resize && $mq.above(640)">
			<div class="svg-animated">
				<svg-1-section-8 />
			</div>
			<div class="content-position">
				<div class="title">Activitatea echipei <span class="grey">în timp real</span></div>
				<div class="desc">Urmărește, în timp real, actualizările de progres, promisiunile zilnice, realizarea de taskuri și mesajele. Stai la curent cu toate lucrurile importante care se întâmplă în companie.</div>
			</div>
		</div>
		<div class="container-section-mobile" v-else>
			<div class="title-v2">Activitatea echipei <span class="grey">în timp real</span></div>
			<div class="desc-v2">Urmărește, în timp real, actualizările de progres, promisiunile zilnice, realizarea de taskuri și mesajele. Stai la curent cu toate lucrurile importante care se întâmplă în companie.</div>
			<div class="svg-animated-v2">
				<svg-2-section-8 />
			</div>
		</div>
	</div>
</template>

<script>
	import Svg1Section8 from '../../assets/landing/mobile/Svg01Section08'
	import Svg2Section8 from '../../assets/landing/mobile/SvgV2Section08'

	export default {
		data() {
			return {

			}
		},
		components: {
			Svg1Section8,
			Svg2Section8
		},
		mounted(){
		}
	}
</script>